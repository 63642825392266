import React from "react";

const ElectionofDirectors = () => {
  return (
    <React.Fragment>
      <div className="text-[#737373] xs:my-4 xs:mx-4 lg:mb-12 lg:mx-40 xsm:mx-80 xs:mb-16">
        <h1 className="md:text-3xl xs:text-xl md:my-8 xs:mb-4 text-black xs:text-center lg:text-left">
          ELECTION OF DIRECTORS
        </h1>

        <p className="font-medium mb-3">
          Notice of Election of Directors{" "}
          <br />
          <a
            className="text-black text-xs hover:underline font-semibold"
            href="https://cdn.shopify.com/s/files/1/0845/6591/8016/files/Election_of_Directors.pdf"
          >
            Click Here
          </a>
        </p>

        <p className="font-medium mb-3">
          Notice of Extra Ordinary General Meeting{" "}
          <br />
          <a
            className="text-black text-xs hover:underline font-semibold"
            href="https://cdn.shopify.com/s/files/1/0845/6591/8016/files/Notice_of_Extra_Ordinary_General_Meeting.pdf"
          >
            Click Here
          </a>
        </p>
      </div>
    </React.Fragment>
  );
};

export default ElectionofDirectors;
